import React from "react";
import Navigation from "./components/Navigation.jsx";
import Hero from "./components/hero.jsx";
import About from "./components/about.jsx";
import Announce from "./components/announce.jsx";
import Brands from "./components/brands.jsx";
import Naria from "./components/naria.jsx";
import CaseStudies from "./components/caseStudies.jsx";
import Principles from "./components/principles.jsx";

import "./styles/App.scss";

function App() {
	return (
		<div className='main'>
			<Navigation />
			<Hero />
			<Naria />
			<Brands />
			<About />
			<Announce />
		</div>
	);
}

export default App;
