import React from "react";
import { UnicornWolf, UnicornWolfText } from "./SvgCollection";
import "../styles/Hero.scss";

const Hero = () => {
	return (
		<div className='hero' id='home'>
			<div className='textSection'>
				<div className='textLockUp'>
					<h1>I’m Parker, </h1>
					<h2>
						Principal Product Designer 🦄 creating products that{" "}
						<span className='bold'>think</span>,{" "}
						<span className='bold'>engage</span>, and{" "}
						<span className='bold'>evolve</span>.
					</h2>
					<p>
						With <strong>13+ years</strong> of experience, I create AI-driven,
						products to deliver intuitive, memorable, human experiences that
						don’t just solve a problem—they evolve with you.
					</p>
					<p className='experience'>
						Currently <strong>Chief Design Officer</strong> and{" "}
						<strong>co-founder</strong> of:
						<a href='https://naria.ai/' target='_blank'>
							<img
								src='https://res.cloudinary.com/drauw0cls/image/upload/v1739155323/Logo_s894ff.svg'
								alt='Naria Logo'
							/>
						</a>
					</p>
				</div>
			</div>

			<UnicornWolfText />
		</div>
	);
};

export default Hero;
