import React from "react";

import "../styles/Brands.scss";

const Brands = () => {
	return (
		<div className='brands' id='brands'>
			<div className='contentLockUp'>
				<p>{`I’ve built experience for companies like:`}</p>
			</div>
			<img
				src='https://res.cloudinary.com/drauw0cls/image/upload/v1739156876/parker-partners_fhnzgu.png'
				alt='Brands I have worked with'
			/>
		</div>
	);
};

export default Brands;
