import React, { useState, useEffect } from "react";
import { DefaultWolf } from "./SvgCollection";
import "../styles/About.scss";

const About = () => {
	const titles = [
		" Product Designer",
		" Storyteller",
		" AI Designer",
		" UX Designer",
		" Researcher",
		" Unicorn",
		" Builder",
	];
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((currentIndex + 1) % titles.length);
		}, 2000);

		return () => clearInterval(interval);
	}, [currentIndex]);

	return (
		<div className='about' id='about'>
			<DefaultWolf />
			<div className='lockUp'>
				<h1>
					{`I'm a`}
					<span className='highlightAnimation'>{titles[currentIndex]}</span>
				</h1>
				<h6 className='watermark'>{`Who`}</h6>
				<h2>{`I believe the best products don’t just solve a problem—they evolve with them.`}</h2>

				<div className='paragraph'>
					<p>{`With 13 years of experience across B2C and B2B, I’ve worn many hats—from full-stack engineer to product designer—building experiences for startups, agencies, and some of the biggest media companies today. Most recently, I co-founded Naria, a Techstars-backed AI company that empowers families to create illustrated stories in digital, audio, video, and print formats—all from a single source.`}</p>
					<p>{`Before Naria, I spent six years at Amazon, leading V1 products, growth initiatives, and design teams across AWS, Prime, Amazon.com, and Amazon Games. My worked ranged from productivity tools, automation builders, immersive shopping experiences to pioneering gamified, personalized, and playful experiences across Amazon’s entire ecosystem—proving that delight drives engagement, retention, and long-term loyalty.`}</p>
					<p>{`Outside of work, you’ll find me in my wood shop, adventuring with my wife and our derpy huskies, whipping up a tasty meal, or enjoying a glass of wine or whiskey.`}</p>
					<div className={"taglines"}>
						<span className='bold'>{`Ex-Amazon Design Leader`}</span> |{" "}
						<span className='bold'>{`Techstars 2024 Founder`}</span> |{" "}
						<span className='bold'>{`Award-Winning Designer`}</span>
					</div>
					<div className='contactCTA'>
						<p className='overline'>View my:</p>
						<a
							href='https://res.cloudinary.com/drauw0cls/image/upload/q_100/v1703890352/Reed-Resume-2023.jpg'
							className='link'
							target='_blank'
							download
						>
							Resume
						</a>
						<a
							href='https://www.linkedin.com/in/parker-reed-design-leader/'
							className='link'
							target='_blank'
						>
							Linkedin
						</a>
						<p className='overline'>Or</p>
						<a
							href='mailto:parker@parkerhreed.com?cc=parker@ghostcatcreative.com&subject=Howdy Parker!&body=I found your portfolio and I had to reach out!'
							className='link'
							target='_blank'
						>
							Email me
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
