import React from "react";

import "../styles/Announce.scss";

const Announce = () => {
	return (
		<div className='announce' id='case-studies'>
			<p>
				I’ve been heads down creating the future of family storytelling but...
			</p>
			<div className='contentLockUp'>
				<h1 className='announcement'>
					{`📩 Reach out for work examples and `}
					<a
						href='mailto:parker@parkerhreed.com?cc=parker@ghostcatcreative.com&subject=Howdy Parker!&body=I want to see the goods! Send me some work examples.'
						target='_blank'
						className='link'
					>{`let’s talk!`}</a>
				</h1>
				<h6 className='watermark'>{``}</h6>
				<p>
					{`I'm currently updating my portfolio with new case studies. In the meantime, please don’t hesitate to `}
					<a
						href='mailto:parker@parkerhreed.com?cc=parker@ghostcatcreative.com&subject=Howdy Parker!&body=I want to see the goods! Send me some work examples.'
						target='_blank'
						className='link'
					>{`reach out `}</a>{" "}
					{`— I’d be happy to share decks that walk through my process and work!`}
				</p>
			</div>
		</div>
	);
};

export default Announce;
