import React from "react";

import "../styles/Naria.scss";

const Naria = () => {
	return (
		<div className='naria'>
			<div className='gallery'>
				<a href='https://naria.ai' target='_blank' rel='noreferrer'>
					<img
						src='https://res.cloudinary.com/drauw0cls/image/upload/v1739157714/NariaCasestudy_qaco9j.png'
						alt='Naria gallery'
					/>
				</a>
			</div>
		</div>
	);
};

export default Naria;
